import Vue from 'vue'
import App from './App.vue'
import router from './router'//router
import store from './store'//vuex

// 样式重置
import "@/assets/css/reset.less"
// :root变量
import "@/assets/css/root.less"
//vxe-table相关样式修改
import "@/assets/css/vxe-table/index.less"
//公共样式
import "@/assets/css/element/select.less"
import "@/assets/css/element/index.less"
import "@/assets/css/element/page.less"
import '@/assets/css/map/map.less'


// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//vxe-table
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
VXETable.setup({
	size: "medium",
	loadingText: "数据加载中",
	table: {
		showHeader: true,
		border: true,
		resizable: true,
		autoResize: true,
	},
	pager: {
		pageSize: 10,
		pageSizes: [10, 20, 50, 100],
		layouts: ['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'Total']
	}
})
Vue.use(VXETable)



// 使用钩子函数对路由进行权限跳转
import { Route } from 'vue-router';
router.beforeEach((to: Route, from: Route, next) => {
	// console.log(to)
	// console.log(from)
	document.title = `${(to.meta as { title: string }).title} | 开放平台管理系统`;
	const role = store.state.id;
	// console.log((!role && to.path !== '/login'))
	if (!role && to.path !== '/login') {
		next('/login');
	} else {
		next();
	}
});


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
