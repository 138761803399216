/**
 * 使用 instance 封装 get post uploadFile getFile login
 * instance 是 axios 的实例
 * Loading 是 element-ui中的loading 实例
 * 使用 async await
 * 功能如下
 *  1、任何请求都进行加载loading
 *  2、只要是通过instance 进行请求的接口报错都进行提示报错
 */
import { instance } from "@/util/http/header";
import { Message } from "element-ui";
import { saveAs } from 'file-saver';


// get请求 
export const getApi = async (url: string, params?: any) => {
    try {
        let res = await instance.get(url, {
            params
        })
        return res
    } catch (error) {
        console.log(error)
        // Message.error(error)
    }
}

//post请求
export const postApi = async (url: string, data: any) => {
    try {
        let res = await instance.post(url, data)
        return res
    } catch (error) {
        console.log(error)
        // Message.error(error)
    }
}


export const LoginApi = async (data: any) => {
    //创建表单数据格式
    const formData = new FormData();
    //解构data
    const { username, password } = data;
    //添加到表单数据中
    formData.append("username", username);
    formData.append("password", password);
    //设置请求头
    const headers = {
        "Content-Type": "multipart/form-data",
    };
    try {
        let res = await instance.post("login", formData, { headers })
        return res
    } catch (error) {
        console.log(error)
        // Message.error(error)
    }
}

//下载文件
export const getFile = async (url: string, form?: any) => {
    try {
        let res = await instance.get(url, { params: { ...form }, responseType: 'blob' })  // 表示服务器将返回二进制数据)

        // 创建一个 Blob 实例，表示从服务器接收到的数据 
        const blob = new Blob([res.data], { type: 'application/octet-stream' });

        // 从 Content-Disposition 头获取文件名
        const contentDisposition = res.headers['content-disposition'];

        let filename = 'file.xlsx';
        if (contentDisposition) {
            filename = contentDisposition.split('=')[1];
        }

        // 使用 file-saver 库保存文件
        saveAs(blob, filename);
    } catch (error) {
        console.error('导出文件失败', error);
    }

}