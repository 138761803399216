import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/login/Login.vue'


import { back_stage_management } from "./back_stage_management"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: Login
	},
	{
		path: '/back_system_home',
		name: 'back_system_home',
		meta: {
			title: '后台系统管理',
		},
		children: [
			...back_stage_management
		],
		component: () => import('@/views/back_system/back_stage_management/home/BackHome.vue')
	},
	{
		path: "/big_screen_home",
		name: "big_screen_home",
		meta: {
			title: "大屏"
		},
		children: [
			{
				path: '/bigscreen_home',
				name: 'bigscreen_home',
				meta: {
					title: '首页',
				},
				component: () => import('@/views/big_screen/Index/BigScreenIndex.vue')
			},
			{
				path: '/screen_farm_info',
				name: 'screen_farm_info',
				meta: {
					title: '园区介绍', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/Index/components/screen_farm_info/ScreenFarmInfo.vue')
			},
			{
				path: '/screen_video_info',
				name: 'screen_video_info',
				meta: {
					title: '视频监控', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/Index/components/screen_video_info/ScreenVideoInfo.vue')
			},
			{
				path: '/weather_watch',
				name: 'weather_watch',
				meta: {
					title: '气象监测', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/weather_watch/WeatherWatch.vue')
			},
			{
				path: '/soil_watch',
				name: 'soil_watch',
				meta: {
					title: '墒情监测', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/soil_watch/SoilWatch.vue')
			},
			{
				path: '/pest_watch',
				name: 'pest_watch',
				meta: {
					title: '虫情监测', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/pest_watch/PestWatch.vue')
			},
			{
				path: '/irr_watch',
				name: 'irr_watch',
				meta: {
					title: '智能灌溉', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/irr_watch/IrrWatch.vue')
			},
			{
				path: '/task_watch',
				name: 'task_watch',
				meta: {
					title: '作业监控', //左上角点击查看详情的详情页面
				},
				component: () => import('@/views/big_screen/task_watch/TaskWatch.vue')
			},

		],
		component: () => import('@/views/big_screen/home/BigScreenHome.vue')
	}

]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

export default router
