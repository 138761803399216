<template>
    <div class="login flex-row" ref="login">
        <div class="diqiu flex-col">
            <div class="title_first"></div>
            <div class="title_last"></div>
            <div class="login_box flex-col">
                <div class="welcome_text"></div>
                <div class="form_box flex-row first_box">
                    <div class="icon_con flex-row">
                        <div class="box_icon user_icon"></div>
                    </div>
                    <div class="box_sy"></div>
                    <input type="text" maxlength="20" v-model="form.username" placeholder="账号" />
                </div>
                <div class="form_box flex-row last_box">
                    <div class="icon_con flex-row">
                        <div class="box_icon psd_icon"></div>
                    </div>
                    <div class="box_sy"></div>
                    <input type="password" maxlength="20" v-model="form.password" placeholder="密码"
                        @keydown.enter="login" />
                </div>
                <div class="login_btn" @click="login">登录</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import jsencrypt from "jsencrypt";
import { LoginApi, getApi } from "@/util/http";
@Component({})
export default class Login extends Vue {
    private form = {
        username: "",
        password: "",
    };

    private handlerParams(): { username: string; password: string } {
        // 创建一个新的JSEncrypt对象
        const encrypt = new jsencrypt();
        // 设置公钥，公钥由后台服务器提供的
        encrypt.setPublicKey(
            "MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAMB8DbRxGfu1aZTdH2sQDpScf7UrOqhkC7Qkl0OKz7CBlOnwkqeQUEExrxOxojSa4DT8nR2xjbALqbd3mxPqXBd4uT0o7FqyO0zdjaUhIuSzpVEbqoT0JgoTKdsxN/ZaPKAjTynGOIIMtMLlFEnnd+iYT0kTSJcwXJjL2QqLUlzHAgMBAAECgYApVrsuxJea+nQpd2/282TeP9ohk54uagYlczJVXCuMsdfEgllMyW6jAcOjDCRU/eonFxW7OdW/OFIDyBkzJsMOG+UMBFVxJCgnz5N6cChyoNDpNq1J8AHeANJHdZbxOE9TFc9Gh2ujVbHRlFovBqFUmhb/jejeoH+ljsvkrlrFkQJBAOwF2rYSChHn0QG6V02JHXPGDKW7o6W8DAU7bU3nyIWRmWCnOZB0xmpYMlqGtcqC7lWKy8gke023XX8F1UIk8+kCQQDQxs+uNgL4OYL0/iMb4gtSy9wojRIQTft1i3rby0yHKG1pnipBHGxmaQTC3kXoKwdnPger7mzxnAJYM+NqeM0vAkBNcopXDkpRn9DckUnOfny0nbtOsui7HJ6RJPP0CWeqNKGwro7cEQV+pdw4ClYi0p3kYfwGe7oeQVojsUVORBPpAkBdfRmUtmUACKjm0kKggsl1SwqMMherq2/2A/6BG/lp8KWGDSh4vaSg+1gBdw0w2MvY5IME76BjVOwL2Th1/iBLAkAvWzdNs+5sTfoz3Yq4zxLlNNhiv6VWZ3yTLS50OUBEl1OV+VK+wSqvYSpN7WsDAe4o2VwDW2VJ1RdEagFwhYsM"
        );
        let username = "";
        let password = "";
        if (this.form.username)
            username = encrypt.encrypt(this.form.username) || "";
        if (this.form.password)
            password = encrypt.encrypt(this.form.password) || "";
        return { username, password };
    }

    //event
    private async login() {
        if (this.form.password && this.form.password.length >= 6) {
            const { username, password } = this.handlerParams();
            if (username && password) {
                const {
                    code,
                    data: { id, name },
                }: any = await LoginApi(this.handlerParams());
                if (code === 200) {
                    this.$store.dispatch("setUserId", id);
                    this.$store.dispatch("setUserName", name);
                    localStorage.setItem("id", id);

                    const { data }: any = await getApi("menu/getCurrentMenus");
                    // 这个注释 是去往后台管理系统的
                    // const result: any = this.$store.state.menuList.find(
                    //     (item: any) => item.name === "后台管理系统"
                    // );
                    // this.$router.push(result.children[0].children[0].url);
                    this.$router.push("/bigscreen_home");
                    this.$store.dispatch("setMenuList", data);
                }
            } else {
                this.$message.error("账号或密码不能为空");
            }
        } else {
            this.$message.error("密码长度不能小于6位");
        }
    }
}
</script>

<style scoped lang="less">
.login {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    background-image: url("@/assets/image/login/beijing.png");
}

.diqiu {
    width: 7.18rem;
    height: 7.18rem;
    align-items: center;
    position: relative;
}

.diqiu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 7.18rem;
    height: 7.18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/image/login/diqiu.png");
    animation: spin 15s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.title_first {
    width: 4.22rem;
    height: 0.49rem;
    margin-top: 0.99rem;
    background-repeat: no-repeat;
    background-size: 4.22rem 0.49rem;
    background-image: url("@/assets/image/login/font.png");
}

.title_last {
    width: 3.7rem;
    height: 0.49rem;
    margin: 0.18rem 0 0.25rem 0;
    background-repeat: no-repeat;
    background-size: 3.7rem 0.49rem;
    background-image: url("@/assets/image/login/font1.png");
}

.login_box {
    width: 4.7rem;
    height: 3.7rem;
    position: relative;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 4.7rem 3.7rem;
    background-image: url("@/assets/image/login/xiaobg.png");

    .welcome_text {
        width: 1.31rem;
        height: 0.29rem;
        position: absolute;
        top: 0.39rem;
        background-repeat: no-repeat;
        background-size: 1.31rem 0.29rem;
        background-image: url("@/assets/image/login/hydl.png");
    }

    .form_box {
        width: 3.68rem;
        height: 0.48rem;
        align-items: center;
        border-radius: 0.04rem;
        border: 1px solid #7799ff;
        background: rgba(23, 71, 166, 0.8);
        opacity: 0.9;

        .icon_con {
            width: 0.5rem;
            height: 0.48rem;
            justify-content: center;
            align-items: center;
        }

        .box_icon {
            background-repeat: no-repeat;
        }

        .user_icon {
            width: 0.18rem;
            height: 0.18rem;
            background-size: 0.18rem 0.18rem;
            background-image: url("@/assets/image/login/zh.png");
        }

        .psd_icon {
            width: 0.14rem;
            height: 0.18rem;
            background-size: 0.14rem 0.18rem;
            background-image: url("@/assets/image/login/pass.png");
        }

        .box_sy {
            width: 1px;
            height: 0.27rem;
            background: #345fbe;
            margin-right: 0.13rem;
        }

        input {
            width: calc(100% - 0.64rem);
            height: 100%;
            color: #c7d6ff;
            font-size: 0.14rem;
            background-color: transparent !important;
        }
    }

    .first_box {
        margin-top: 1.13rem;
    }

    .last_box {
        margin: 0.2rem 0 0.26rem 0;
    }

    .login_btn {
        width: 3.68rem;
        height: 0.48rem;
        background: #007de9;
        border-radius: 4px;
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.48rem;
        cursor: pointer;
    }
}

input::-webkit-input-placeholder {
    color: #fff;
    /* 把placeholder颜色改为fff */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999999s;
    -webkit-transition: color 9999999s ease-out,
        background-color 9999999s ease-out;
}
</style>
