export const back_stage_management = [
    //园区管理
    {
        path: '/garden_area_info',
        name: 'garden_area_info',
        meta: {
            title: '园区信息',
        },
        component: () => import('@/views/back_system/back_stage_management/garden_area_management/garden_area_info/Garden_area_info.vue')
    },
    {
        path: '/garden_area_farm',
        name: 'garden_area_farm',
        meta: {
            title: '园区农机',
        },
        component: () => import('@/views/back_system/back_stage_management/garden_area_management/garden_area_farm/Garden_area_farm.vue')
    },
    {
        path: '/garden_area_industry',
        name: 'garden_area_industry',
        meta: {
            title: '园区产业',
        },
        component: () => import('@/views/back_system/back_stage_management/garden_area_management/garden_area_industry/Garden_area_industry.vue')
    },
    // 缺一个园区导览
    {
        path: '/staff_info',
        name: 'staff_info',
        meta: {
            title: '员工信息',
        },
        component: () => import('@/views/back_system/back_stage_management/garden_area_management/staff_info/StaffInfo.vue')
    },
    {
        path: '/shopping_plantform_link',
        name: 'shopping_plantform_link',
        meta: {
            title: '电商链接',
        },
        component: () => import('@/views/back_system/back_stage_management/garden_area_management/shopping_plantform_link/ShoppingPlantFormLink.vue')
    },


    //生产管理
    {
        path: '/plot_management',
        name: 'plot_management',
        meta: {
            title: '地块管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/plot_management/PlotManagement.vue')
    },
    {
        path: '/breed_management',
        name: 'breed_management',
        meta: {
            title: '作物管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/breed_management/BreadManagement.vue')
    },
    {
        path: '/plant_management',
        name: 'plant_management',
        meta: {
            title: '种植管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/plant_management/PlantMangement.vue')
    },
    {
        path: '/farm_management',
        name: 'farm_management',
        meta: {
            title: '农事管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/farm_management/FarmManagement.vue')
    },
    {
        path: '/recovery_management',
        name: 'recovery_management',
        meta: {
            title: '采收管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/recovery_management/RecoveryManagement.vue')
    },
    {
        path: '/inventory_management',
        name: 'inventory_management',
        meta: {
            title: '库存管理',
        },
        component: () => import('@/views/back_system/back_stage_management/production_management/inventory_management/InventoryManagement.vue')
    },


    // 信息发布
    {
        path: '/show_production',
        name: 'show_production',
        meta: {
            title: '农产品展示',
        },
        component: () => import('@/views/back_system/back_stage_management/issue_message/show_production/ShowProduction.vue')
    },
    {
        path: '/news_policy',
        name: 'news_policy',
        meta: {
            title: '新闻政策',
        },
        component: () => import('@/views/back_system/back_stage_management/issue_message/news_policy/NewsPolicy.vue')
    },
    {
        path: '/project_release',
        name: 'project_release',
        meta: {
            title: '项目发布',
        },
        component: () => import('@/views/back_system/back_stage_management/issue_message/project_release/ProjectRelease.vue')
    },

    // 助农服务
    {
        path: '/farm_support',
        name: 'farm_support',
        meta: {
            title: '农技支持',
        },
        component: () => import('@/views/back_system/back_stage_management/farm_server/farm_support/FarmSupport.vue')
    },
    {
        path: '/pest_cure',
        name: 'pest_cure',
        meta: {
            title: '虫害防治',
        },
        component: () => import('@/views/back_system/back_stage_management/farm_server/pest_cure/PestCure.vue')
    },
    {
        path: '/disease_cure',
        name: 'disease_cure',
        meta: {
            title: '病害防治',
        },
        component: () => import('@/views/back_system/back_stage_management/farm_server/disease_cure/DiseaseCure.vue')
    },

    // 金融服务
    {
        path: '/sell_management',
        name: 'sell_management',
        meta: {
            title: '销售管理',
        },
        component: () => import('@/views/back_system/back_stage_management/financial_service/sell_management/SellManagement.vue')
    },

    // 设备管理
    {
        path: '/weather_device',
        name: 'weather_device',
        meta: {
            title: '气象设备',
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/weather_device/WeatherDevice.vue')
    },
    {
        path: '/soil_device',
        name: 'soil_device',
        meta: {
            title: '墒情设备', //太阳能
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/soil_device/SoilDevice.vue')
    },
    {
        path: '/pest_device',
        name: 'pest_device',
        meta: {
            title: '虫情测报仪', //太阳能
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/pest_device/PestDevice.vue')
    },
    {
        path: '/irrigate_device',
        name: 'irrigate_device',
        meta: {
            title: '灌溉设备', //太阳能
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/irrigate_device/IrrigateDevice.vue')
    },
    {
        path: '/video_device',
        name: 'video_device',
        meta: {
            title: '监控设备', //太阳能
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/video_device/VideoDevice.vue')
    },
    {
        path: '/pest_light_device',
        name: 'pest_light_device',
        meta: {
            title: '物联网杀虫灯',
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/pest_light_device/PestLightDevice.vue')
    },
    {
        path: '/truck_device',
        name: 'truck_device',
        meta: {
            title: '喷灌车',
        },
        component: () => import('@/views/back_system/back_stage_management/device_management/truck_device/TruckDevice.vue')
    },
    //告警管理
    {
        path: '/offline_alert',
        name: 'offline_alert',
        meta: {
            title: '离线告警',
        },
        component: () => import('@/views/back_system/back_stage_management/alert_management/offline_alert/OfflineAlert.vue')
    },
    {
        path: '/over_alert',
        name: 'over_alert',
        meta: {
            title: '超限告警',
        },
        component: () => import('@/views/back_system/back_stage_management/alert_management/over_alert/OverAlert.vue')
    },


    //系统管理
    {
        path: '/role_management',
        name: 'role_management',
        meta: {
            title: '角色与权限',
        },
        component: () => import('@/views/back_system/back_stage_management/system_management/role_management/RoleManagement.vue')
    },
    {
        path: '/account_management',
        name: 'account_management',
        meta: {
            title: '账号管理',
        },
        component: () => import('@/views/back_system/back_stage_management/system_management/account_management/AccountManagement.vue')
    },
    {
        path: '/edit_psd',
        name: 'edit_psd',
        meta: {
            title: '修改密码',
        },
        component: () => import('@/views/back_system/back_stage_management/system_management/edit_psd/EditPsd.vue')
    },
    {
        path: '/menu_management',
        name: 'menu_management',
        meta: {
            title: '菜单管理',
        },
        component: () => import('@/views/back_system/back_stage_management/system_management/menu_management/MenuManagement.vue')
    },

] 