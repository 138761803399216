
import router from "@/router";
import axios from "axios";
import { Loading } from "element-ui";
import Vue from "vue";

const vue = new Vue();
const instance = axios.create({
    baseURL: "taihe/",
    timeout: 20000,
})

// 创建Loading实例
let loadingInstance: any;

const toPath = (path: string) => {
    router.push({ path }).catch(err => {
        // 忽略 NavigationDuplicated 错误
        if (err.name !== 'NavigationDuplicated') {
            // 如果不是 NavigationDuplicated 错误，那么就抛出这个错误
            throw err;
        }
    });
}

// 请求拦截
instance.interceptors.request.use((config) => {
    loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    config.headers.set('Authorization', localStorage.getItem('token_type')! + localStorage.getItem('access_token')!);
    //检查传递的参数是否为空 如果为空那么删除为空的字段
    if (config.method === 'post') {
        for (let key in config.data) {
            if (config.data[key] === '' || config.data[key] === null || config.data[key] === undefined) {
                delete config.data[key];
            }
        }
    }
    //get请求也一样
    if (config.method === 'get') {
        for (let key in config.params) {
            if (config.params[key] === '' || config.params[key] === null || config.params[key] === undefined) {
                delete config.params[key];
            }
        }
    }
    return config;
}, (error) => {
    if (axios.isCancel(error)) {
        console.log('Request was cancelled by the user');
    } else {
        // 处理其他类型的错误
        toPath("/login")
        vue.$message.error("请求失败");
        return
    }
});


// 响应拦截
instance.interceptors.response.use((response) => {

    // 隐藏加载动画
    loadingInstance.close();
    if (response.status === 200) {
        const { data } = response;
        if (data && data.code === 400 || data.code === 401 || data.code === 402 || data.code === 417) {
            vue.$message.error(data.message);
            toPath("/login")
            return;
        } else if (data && data.code === 200) {
            return data;
        } else if (data && data.code === 500) {
            vue.$message.error(data.message);
            return
        } else if (data.code === 711 || data.code === 700) {
            vue.$message.error(data.message);
            return data
        } else {
            /** 如果 请求 类型为 responseType: 'blob' 做特殊的处理*/
            if (response.config.responseType === 'blob') {
                return response
            }
            console.log(response)
            vue.$message.error(data.message);
            return
        }
    } else {
        toPath("/login")
        vue.$message.error("请求失败");
        return
    }
}, (error) => {
    // if (axios.isCancel(error)) {
    //     // 用户中止请求，这里可以根据需要进行处理，例如不执行任何操作或者显示一个用户取消请求的消息
    //     vue.$message.info("请求已被取消");
    //     return;
    // }

    // if (error.response) {
    //     // 请求已发出，但服务器响应表示出错（例如4xx或5xx错误）
    //     // ... 错误处理逻辑
    //     toPath("/login");
    //     vue.$message.error("请求失败");
    // } else if (error.request) {
    //     // 请求已经创建，但尚未发出
    //     console.log(error.request);
    // } else {
    //     // 发生了触发请求错误的问题
    //     console.log('Error', error.message);
    // }
    // // 可以在这里执行一些通用的错误处理，比如记录日志等
    // return Promise.reject(error);
    // 对响应错误做点什么
    vue.$message.error("请求失败");
    toPath("/login")
    return
});


export { instance }